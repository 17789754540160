import * as types from '@/types'

import AuthService from '@/modules/auth/services/AuthService'
//import ApiService from '@/modules/api/services/ApiService'
import * as tokens from './TokenService'
import onLoginError from './LoginErrorService'
import onLoginSuccess from './LoginSuccessService'

const loginRoute = 'login'
const dashboardRoute = 'events'
const regExtraRoute = 'registrationExtra'

const ensureAuthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters
  const isRegComplete = AuthService.getIsRegCompleteToken()
  const loginpath =
    window.location.pathname.length > 1 && window.location.pathname !== '/login' ? window.location.pathname : ''
  const loginCallbackRoute = loginpath
    ? {
        name: `${loginRoute}`,
        query: { redirect: loginpath }
      }
    : { name: `${loginRoute}` }
  store.commit(types.HIDE_NAVIGATION)

  /**
   * Prevent incomplete registration from skipping extra user data form
   */
  if (isAuthenticated && to.path !== `/registration/extra` && isRegComplete === 'false') {
    next({ name: `${regExtraRoute}` })
    return
  }

  if (to.matched.some(record => record.meta.authenticatedOnly) && !isAuthenticated) {
    next(loginCallbackRoute)
    return
  }

  next()
}

const ensureUnauthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters

  store.commit(types.HIDE_NAVIGATION)

  if (
    to.name === 'registrationActivation' &&
    to.matched.some(record => record.meta.unauthenticatedOnly) &&
    isAuthenticated
  ) {
    store.commit(types.AUTH_LOGOUT)
    //ApiService.getClientToken()
    next()
    return
  }

  if (to.matched.some(record => record.meta.unauthenticatedOnly) && isAuthenticated) {
    next(from.matched.length ? false : `/${dashboardRoute}`)
    return
  }

  next()
}

export default {
  loginRoute,
  dashboardRoute,
  ensureAuthenticated,
  ensureUnauthenticated,
  onLoginError,
  onLoginSuccess,
  getAuthToken: tokens.getAuthToken,
  setAuthToken: tokens.setAuthToken,
  removeAuthToken: tokens.removeAuthToken,
  /*getClientToken: tokens.getClientToken,
  setClientToken: tokens.setClientToken,
  removeClientToken: tokens.removeClientToken,*/
  getAuthTokenExpiry: tokens.getAuthTokenExpiry,
  setAuthTokenExpiry: tokens.setAuthTokenExpiry,
  removeAuthTokenExpiry: tokens.removeAuthTokenExpiry,
  getIsRegCompleteToken: tokens.getIsRegCompleteToken,
  setIsRegCompleteToken: tokens.setIsRegCompleteToken,
  removeIsRegCompleteToken: tokens.removeIsRegCompleteToken
}
