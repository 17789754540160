import ApiService from '@/modules/api/services/ApiService'
import * as types from '@/types'

const state = {
  user: {},
  registrationStatus: null,
  isUserAlreadyRegistered: false,
  activationStatus: null,
  emailChangeActivationStatus: null,
  emailChangeDuringRegistrationStatus: null
}

const getters = {}

const actions = {
  async [types.REGISTRATION_REQUEST]({ commit }, user) {
    try {
      const newUser = await ApiService.save('register', user)
      commit(types.REGISTRATION_SUCCESS)
      return Promise.resolve(newUser)
    } catch (error) {
      commit(types.REGISTRATION_ERROR)
      return Promise.reject(error)
    }
  },
  async [types.REGISTRATION_ACTIVATION_REQUEST]({ commit }, token) {
    try {
      await ApiService.get('signup/activate', token)
      commit(types.REGISTRATION_ACTIVATION_SUCCESS)
      return Promise.resolve()
    } catch (error) {
      commit(types.REGISTRATION_ACTIVATION_ERROR)
      return Promise.reject()
    }
  },
  async [types.EMAIL_CHANGE_ACTIVATION_REQUEST]({ commit }, token) {
    try {
      await ApiService.get('email/activate', token)
      commit(types.EMAIL_CHANGE_ACTIVATION_SUCCESS)
    } catch (error) {
      commit(types.EMAIL_CHANGE_ACTIVATION_ERROR)
    }
  },
  async [types.EMAIL_CHANGE_DURING_REGISTRATION_REQUEST]({ commit }, data) {
    try {
      const { uid, email, password } = data
      const payload = {
        email,
        password
      }
      const url = `register/${uid}`
      const slug = `email`
      const response = await ApiService.update(url, slug, payload)
      commit(types.EMAIL_CHANGE_DURING_REGISTRATION_SUCCESS)
      return Promise.resolve(response)
    } catch (error) {
      commit(types.EMAIL_CHANGE_DURING_REGISTRATION_ERROR)
      return Promise.reject(error)
    }
  }
}

const mutations = {
  [types.REGISTRATION_SUCCESS](state) {
    state.registrationStatus = 'success'
    state.isUserAlreadyRegistered = false
  },
  [types.REGISTRATION_ERROR](state) {
    state.registrationStatus = 'error'
    state.isUserAlreadyRegistered = false
  },
  [types.CLEAR_REGISTRATION_STATUS](state) {
    state.registrationStatus = null
    state.isUserAlreadyRegistered = false
  },
  [types.REGISTRATION_SET_USER_ALREADY_REGISTERED](state) {
    state.isUserAlreadyRegistered = true
  },
  [types.REGISTRATION_SET_USER](state, user) {
    if (process.env.VUE_APP_BRANCH == 'swim' && user.uid) {
      var signUpProperties = {
        contentType: 'Page',
        id: user.uid.toString(),
        name: 'Registration'
      }
      window['bp']('track', 'signUp', signUpProperties)
    }

    state.user = user
  },
  [types.REGISTRATION_SET_USER_profile](state, profile) {
    state.user.profile = profile
  },
  [types.REGISTRATION_ACTIVATION_SUCCESS](state) {
    state.activationStatus = 'success'
  },
  [types.REGISTRATION_ACTIVATION_ERROR](state) {
    state.activationStatus = 'error'
  },
  [types.REGISTRATION_CLEAR_USER](state) {
    state.user = {}
  },
  [types.EMAIL_CHANGE_ACTIVATION_SUCCESS](state) {
    state.emailChangeActivationStatus = 'success'
  },
  [types.EMAIL_CHANGE_ACTIVATION_ERROR](state) {
    state.emailChangeActivationStatus = 'error'
  },
  [types.EMAIL_CHANGE_DURING_REGISTRATION_SUCCESS](state) {
    state.emailChangeDuringRegistrationStatus = 'success'
  },
  [types.EMAIL_CHANGE_DURING_REGISTRATION_ERROR](state) {
    state.emailChangeDuringRegistrationStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
